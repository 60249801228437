import { render, staticRenderFns } from "./ConversionCampaignsTable.vue?vue&type=template&id=327cb164&scoped=true&"
import script from "./ConversionCampaignsTable.vue?vue&type=script&lang=js&"
export * from "./ConversionCampaignsTable.vue?vue&type=script&lang=js&"
import style0 from "./ConversionCampaignsTable.vue?vue&type=style&index=0&id=327cb164&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327cb164",
  null
  
)

export default component.exports