<template>
  <b-card
    v-if="data"
    class="earnings-card"
  >
    <div
      class="d-flex align-items-center"
      :class="!title ? 'flex-column-reverse' : 'flex-row'"
    >
      <div>
        <b-card-title
          v-if="title"
          class="mb-1"
        >
          {{ title }}
        </b-card-title>
        <div
          v-if="text"
          class="font-small-2"
        >
          {{ text }}
        </div>
        <b-card-text class="text-muted font-small-2">
          <p>
            {{ legend }}
          </p>
        </b-card-text>
      </div>
      <div>
        <!-- chart -->
        <vue-apex-charts
          type="radialBar"
          height="120"
          :options="earningsChart.chartOptions"
          :series="data"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

// const $earningsStrokeColor2 = '#28c76f66'
// const $earningsStrokeColor3 = '#28c76f33'
export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default: () => [0],
    },
    color: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    legend: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'radialBar',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          // comparedResult: [2, -3, 8],
          labels: [''],
          stroke: { width: 0 },
          colors: [$themeColors[this.color]],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 15,
                size: '60%',
              },

              dataLabels: {
                showOn: 'always',
                name: {
                  offsetY: -150,
                  show: true,
                  color: '#888',
                  fontSize: '13px',
                },
                value: {
                  offsetY: -8,
                  color: '#111',
                  fontSize: '18px',
                  show: true,
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>
