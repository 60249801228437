<template>
  <b-card
    no-body
    class="card-browser-states d-flex"
    style="flex: 1"
  >
    <b-card-header>
      <div>
        <b-card-title>Meios de compartilhamento</b-card-title>
        <b-card-text
          v-if="totalPercentData"
          class="font-small-2"
        >
          Percentual de lead
        </b-card-text>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body v-if="sharedMethods && sharedMethods.length === 0">
      <p>
        Não há registros para as campanhas informadas
      </p>
    </b-card-body>

    <b-card-body v-else>
      <b-row>
        <b-col
          v-if="!totalPercentData"
          cols="3"
          class="d-flex justify-content-center align-items-center"
        >
          <span class="font-small-2" />
        </b-col>
        <b-col
          v-if="!totalPercentData"
          cols="3"
          class="d-flex justify-content-center align-items-center"
        >
          <span class="font-small-2">Acessos Únicos</span>
        </b-col>
        <b-col
          v-if="!totalPercentData"
          cols="3"
          class="d-flex justify-content-center align-items-center"
        >
          <span class="font-small-2">Leads</span>
        </b-col>
        <b-col
          v-if="!totalPercentData"
          cols="3"
          class="d-flex justify-content-center align-items-center"
        >
          <span class="font-small-2">Percentual</span>
        </b-col>
      </b-row>

      <b-row
        v-for="(method, idx) in sharedMethods"
        :key="method.description"
        class="browser-states"
      >

        <b-col
          :cols="!totalPercentData ? 3 : 6"
        >
          <b-media
            no-body
            class="d-flex justify-content-between align-items-center"
          >
            <b-media-aside class="mr-1">
              <b-img
                :src="require(`@/assets/images/icons/shared-methods/${method.description.toLowerCase()}.png`)"
                alt="method.title"
              />
            </b-media-aside>
            <b-media-body>
              <h6 class="align-self-center my-auto">
                {{ method.title }}
              </h6>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          v-if="!totalPercentData"
          cols="3"
          class="d-flex justify-content-center align-items-center"
        >
          <span class="font-weight-bold text-body-heading mr-1">{{ method.views }}</span>
        </b-col>
        <b-col
          v-if="!totalPercentData"
          cols="3"
          class="d-flex justify-content-center align-items-center"
        >
          <span class="font-weight-bold text-body-heading mr-1">{{ method.totalCoupon }}</span>
        </b-col>
        <b-col
          :cols="!totalPercentData ? 3 : 6"
          class="d-flex justify-content-end align-items-center"
        >
          <div class="d-flex align-items-center">
            <span class="font-weight-bold text-body-heading mr-1">
              {{ !totalPercentData ? method.conversionPercent : method.totalPercent }}%
            </span>
            <vue-apex-charts
              v-if="chartData.length > 0"
              type="radialBar"
              height="30"
              width="30"
              :options="chartData[idx].options"
              :series="[!totalPercentData ? method.conversionPercent : method.totalPercent]"
            />
          </div>
        </b-col>
      </b-row>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>

import {
  BRow, BCol, BCard, BCardHeader, BCardText, BCardTitle, BCardBody, BMedia, BMediaAside, BMediaBody, BImg,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    // BDropdown,
    // BDropdownItem,
    VueApexCharts,
  },
  props: {
    sharedMethods: {
      type: Array,
      default: () => [],
    },
    totalPercentData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartData: [],
      chartClone: {},
      chartColor: ['#55CD6C', '#0053BF', '#FEC970', '#FB9934', '#CE81C6', '#E65A87'],
      //   chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      browserData: [
        {
          browserImg: require('@/assets/images/icons/whatsapp.png'),
          name: 'Whatsapp',
          // usage: `${this.sharedMethods[0]}%`,
          usage: '10%',
        },
        {
          browserImg: require('@/assets/images/icons/facebook.png'),
          name: 'Facebook',
          usage: '10%',
        },
        {
          browserImg: require('@/assets/images/icons/email.png'),
          name: 'Email',
          usage: '10%',
        },
        {
          browserImg: require('@/assets/images/icons/outros.png'),
          name: 'Outros',
          usage: '10%',
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  watch: {
    sharedMethods() {
      for (let i = 0; i < this.sharedMethods.length; i += 1) {
        const chartClone = JSON.parse(JSON.stringify(this.chart))
        chartClone.options.colors[0] = this.chartColor[i]
        //   chartClone.series[0] = this.chartSeries[i]
        this.chartData.push(chartClone)
      }
    },
  },
}
</script>
