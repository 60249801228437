<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col cols="12">
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            <p><b>Versão Beta.</b> Dados a partir de terça-feira, dia 14 de junho de 2022 às 11:10am.</p>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col
        lg="4"
        class="d-flex flex-column justify-content-between"
      >
        <b-card-text>
          <span>Selecione uma campanha para filtrar o dashboard:</span>
        </b-card-text>
        <v-select
          v-model="campaignsFilterToken"
          placeholder="Todas as campanhas"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="campaignsOptions"
          :clearable="false"
          class="w-100"
          :reduce="val => val.value"
          :value="campaignsFilterToken"
        >
          <template #no-options>
            <span>Não encotramos nenhum resultado :(</span>
          </template>
        </v-select>

      </b-col>
      <b-col
        lg="4"
        class="d-flex flex-column justify-content-between"
      >
        <b-card-text>
          <span>Intervalo da datas:</span>
        </b-card-text>
        <flat-pickr
          id="rangeDate"
          v-model="periodDateFilter"
          class="form-control"
          placeholder="Selecione um período para filtrar..."
          :config="config"
          :value="periodDateFilter"
        />
        <small
          v-if="dateDiff > 90"
          class="text-danger"
        >O período precisa ser no máximo de 90 dias</small>
      </b-col>
      <b-col
        cols="4"
        class="d-flex flex-column justify-content-end"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="w-50"
          @click="refetchData"
        >
          Filtrar Dashboard
        </b-button>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="3">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <statistic-card-horizontal
            icon="TagIcon"
            :statistic="newLeadsData"
            statistic-title="Leads novos"
          />
        </b-overlay>
      </b-col>
      <b-col cols="3">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <statistic-card-horizontal
            icon="Edit2Icon"
            :statistic="inProgressData"
            color="warning"
            statistic-title="Em andamento"
          />
        </b-overlay>
      </b-col>
      <b-col cols="3">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <statistic-card-horizontal
            icon="TrendingUpIcon"
            :statistic="confirmedData"
            color="success"
            statistic-title="Confirmados"
          />
        </b-overlay>
      </b-col>
      <b-col cols="3">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <statistic-card-horizontal
            icon="XCircleIcon"
            :statistic="canceledData"
            color="danger"
            statistic-title="Cancelados"
          />
        </b-overlay>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        cols="2"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
          class="d-flex"
          style="flex: 1"
        >
          <statistic-card-vertical
            icon="Share2Icon"
            :statistic="sharingData"
            statistic-title="Compartilhamentos"
            color="warning"
          />
        </b-overlay>
      </b-col>
      <b-col
        cols="8"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
          class="d-flex"
          style="flex: 1"
        >
          <conversion-statistics
            :data="campaignResumeData"
            title="Resumo da campanha"
          />
        </b-overlay>
      </b-col>
      <b-col cols="2">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
          class="d-flex"
          style="flex: 1"
        >
          <conversion-earnings-chart
            :data="[accessPercentageData]"
            color="danger"
            legend="Conversões X Acessos"
          />
        </b-overlay>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <b-col
            cols="12"
          >
            <b-overlay
              :show="isAPICallInProgress"
              rounded="sm"
            >
              <conversion-earnings-chart
                :data="[engagementPercentageData]"
                title="Engajamento"
                text="Percentual de engajamento da sua campanha"
                legend="• Leads x Acessos únicos"
                color="success"
              />
            </b-overlay>
          </b-col>

          <b-col
            cols="12"
          >
            <b-overlay
              :show="isAPICallInProgress"
              rounded="sm"
            >
              <conversion-statistics
                :data="rewardTypesData"
                title="Indicações por tipo de recompensa"
                vertical-items
              />
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
          class="d-flex"
          style="flex: 1"
        >
          <conversion-shared-methods
            :shared-methods="sharedMethodsData"
            total-percent-data
          />
        </b-overlay>
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
          class="d-flex"
          style="flex: 1"
        >
          <conversion-goal-overview :data="conversionPercentageData" />
        </b-overlay>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <conversion-campaigns-table :table-data="tableCampaingns" />
        </b-overlay>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="6">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
          class="d-flex"
          style="flex: 1"
        >
          <conversion-shared-methods :shared-methods="sharedMethodsData" />
        </b-overlay>
      </b-col>
      <b-col cols="6">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
          class="d-flex"
          style="flex: 1"
        >
          <conversion-tracker :data="newLeadsTrackerFormattedData" />
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'

import {
  BRow, BCol, BCardText, BOverlay, BButton, BAlert,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from '@/utils/flatpickr-component/languages/pt'

import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

import { kFormatter } from '@core/utils/filter'
import {
  onBeforeMount, ref, computed,
} from '@vue/composition-api'
import useConversionDashboard from './useConversionDashboard'

import ConversionStatistics from './ConversionStatistics.vue'

import ConversionEarningsChart from './ConversionEarningsChart.vue'
import ConversionTracker from './ConversionTracker.vue'
import ConversionCampaignsTable from './ConversionCampaignsTable.vue'
import ConversionSharedMethods from './ConversionSharedMethods.vue'
import ConversionGoalOverview from './ConversionGoalOverview.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BButton,
    BAlert,

    ConversionStatistics,
    ConversionTracker,
    ConversionEarningsChart,
    ConversionCampaignsTable,
    ConversionSharedMethods,
    ConversionGoalOverview,
    StatisticCardHorizontal,
    StatisticCardVertical,
    vSelect,
    flatPickr,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    const validDates = date => date.getTime() <= new Date().getTime()

    return {
      filteredCampaing: { label: 'Todas as campanhas', value: '' },
      config: {
        mode: 'range',
        dateFormat: 'm-d-Y',
        altFormat: 'd-m-Y',
        altInput: true,
        locale: Portuguese,
        enable: [
          validDates,
        ],
      },
      data: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      statisticsItems: [],
      sharedMethods: [],
      indicators: 0,
      shareds: 0,
      congratulations: {
        name: '',
        saleToday: '0',
      },
      convertionPercentage: {},
      engagement: [0],
      dataTableDtos: [],
      selected: { campaignToken: '', name: 'Todas as Campanhas' },
      option: [],
      conversionTracker: {
        title: 'Novos leads',
        totalTicket: 163,
        newTicket: 29,
        openTicket: 63,
        responseTime: 1,
        supportTrackerRadialBar: {
          series: [83],
        },
      },
    }
  },
  setup() {
    const {
      dashboardConversionData,
      fetchCampaigns,
      campaignsList,
      tableCampaingns,
      campaignsFilterToken,
      periodDateFilter,
      newLeadsData,
      canceledData,
      inProgressData,
      confirmedData,
      sharingData,
      sharedMethodsData,
      campaignResumeData,
      accessPercentageData,
      conversionPercentageData,
      engagementPercentageData,
      rewardTypesData,
      newLeadsTrackerFormattedData,
      fetchDashboardConversion,
      fetchTableOfCampaigns,
    } = useConversionDashboard()

    const rangeDate = ref(null)
    const dateDiff = computed(() => {
      const rangeSplit = periodDateFilter.value ? periodDateFilter.value.split(' ') : []
      const firstDate = new Date(rangeSplit[0])
      const endDate = new Date(rangeSplit[2])
      const diff = (endDate.getTime() - firstDate.getTime()) / (1000 * 3600 * 24) + 1

      return diff
    })

    onBeforeMount(() => {
      fetchDashboardConversion()
      fetchTableOfCampaigns()
      fetchCampaigns()
    })

    const campaignsOptions = campaignsList

    function refetchData() {
      fetchDashboardConversion()
      fetchTableOfCampaigns()
    }

    return {
      rangeDate,
      dateDiff,

      dashboardConversionData,
      fetchCampaigns,
      tableCampaingns,
      campaignsList,
      campaignsFilterToken,
      campaignsOptions,
      periodDateFilter,
      newLeadsData,
      canceledData,
      inProgressData,
      confirmedData,
      sharingData,
      sharedMethodsData,
      campaignResumeData,
      accessPercentageData,
      conversionPercentageData,
      engagementPercentageData,
      rewardTypesData,
      newLeadsTrackerFormattedData,
      fetchDashboardConversion,
      fetchTableOfCampaigns,
      refetchData,
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  methods: {
    kFormatter,
    getListCampaings() {
      return new Promise((resolve, reject) => {
        useJwt.listCampaing()
          .then(response => {
            const campaingsFiltered = response.data
              .filter(campaing => campaing.status !== 'ARQUIVADA')
              .map(campaing => ({ campaignToken: campaing.token, name: campaing.name }))
            this.option = campaingsFiltered
            this.option.unshift({ campaignToken: '', name: 'Todas as Campanhas' })
            // console.log('campanhas: ', campaingsFiltered)
            return resolve(campaingsFiltered)
          })
          .catch(error => reject(error))
      })
    },
    getStatisticsItems(payload) {
      return new Promise((resolve, reject) => {
        useJwt.statisticsItems(payload)
          .then(response => {
            // console.log('res: ', response)

            // dados de faturamento
            this.congratulations.saleToday = response.data.amount.toString()

            // dados de resumo das campanhas
            this.statisticsItems = [
              {
                icon: 'EyeIcon',
                color: 'light-info',
                title: response.data.views,
                subtitle: 'Visualizações',
                customClass: 'mb-2 mb-xl-0',
              },
              {
                icon: 'MonitorIcon',
                color: 'light-primary',
                title: response.data.uniqueAccess,
                subtitle: 'Acessos únicos',
                customClass: 'mb-2 mb-xl-0',
              },
              {
                icon: 'UserIcon',
                color: 'light-danger',
                title: response.data.leads,
                subtitle: 'Leads',
                customClass: 'mb-2 mb-sm-0',
              },
              {
                icon: 'AwardIcon',
                color: 'light-success',
                title: response.data.conversions,
                subtitle: 'Conversões',
                customClass: '',
              },
            ]

            // estatísticas dos métodos de compartilhamento
            this.sharedMethods = [response.data.whatsApp, response.data.facebook, response.data.email, response.data.other]
            this.$store.commit('dashboard/SET_SHARED_METHODS_VALUES',
              [response.data.whatsApp, response.data.facebook, response.data.email, response.data.other])

            // dados de engajamento
            this.engagement = [response.data.engagement]

            // dados de conversão
            this.convertionPercentage = {
              lead: response.data.leads,
              conversions: response.data.conversions,
              series: [response.data.leadsConvertionPercentage],
            }

            // dados de indicadores e compartilhamentos
            this.indicators = response.data.indicators
            this.shareds = response.data.shareds

            // dados da tabela com detalhes das campanhas
            this.dataTableDtos = response.data.dataTableDtos

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
