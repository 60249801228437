import useJwt from '@/auth/jwt/useJwt'
import { ref } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useConversionDashboard() {
  const toast = useToast()

  const today = new Date()
  const todayLocaleString = today.toLocaleString()
  const ninetyDaysAgo = new Date()
  ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90)
  const ninetyDaysAgoLocaleString = ninetyDaysAgo.toLocaleString()

  const periodDateFilter = ref(null)

  const dashboardConversionData = ref(null)
  const campaignsList = ref([])
  const campaignsFilterToken = ref(null)
  const newLeadsData = ref(0)
  const canceledData = ref(0)
  const inProgressData = ref(0)
  const confirmedData = ref(0)
  const sharingData = ref(0)

  const sharedMethodsData = ref(null)

  const conversionPercentageData = ref({
    lead: 0,
    conversions: 0,
    series: [0],
  })

  const newLeadsTrackerFormattedData = ref({
    indications: 0,
    newleads: 0,
    inProgress: 0,
    completed: 0,
    percent: [0],
  })

  const tableCampaingns = ref(null)

  const campaignResumeData = ref([
    {
      icon: 'EyeIcon',
      color: 'light-info',
      title: 0,
      subtitle: 'Visualizações',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'MonitorIcon',
      color: 'light-primary',
      title: 0,
      subtitle: 'Acessos únicos',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'UserIcon',
      color: 'light-danger',
      title: 0,
      subtitle: 'Leads',
      customClass: 'mb-2 mb-sm-0',
    },
    {
      icon: 'AwardIcon',
      color: 'light-success',
      title: 0,
      subtitle: 'Conversões',
      customClass: '',
    },
  ])
  const accessPercentageData = ref(0)
  const engagementPercentageData = ref(0)
  const rewardTypesData = ref([
    {
      icon: 'DollarSignIcon',
      color: 'light-success',
      subtitle: 'Dinheiro',
      customClass: 'mb-2 mb-xl-0',
      title: 0,
    },
    {
      icon: 'MonitorIcon',
      color: 'light-info',
      subtitle: 'Voucher',
      customClass: 'mb-2 mb-xl-0',
      title: 0,
    },
    {
      icon: 'PercentIcon',
      color: 'light-danger',
      subtitle: 'Percentual',
      customClass: 'mb-2 mb-sm-0',
      title: 0,
    },
  ])

  const endDate = ref(
    `${todayLocaleString.slice(6, 10)}-${todayLocaleString.slice(3, 5)}-${todayLocaleString.slice(0, 2)}`,
  )

  const initDate = ref(
    `${ninetyDaysAgoLocaleString.slice(6, 10)}-${ninetyDaysAgoLocaleString.slice(3, 5)}-${ninetyDaysAgoLocaleString.slice(0, 2)}`,
  )

  const invertDateOrder = date => {
    const invertedDate = `${date.slice(6, 10)}-${date.slice(0, 2)}-${date.slice(
      3,
      5,
    )}`
    return invertedDate
  }

  const fetchCampaigns = () => {
    useJwt
      .listCampaingInDashboard()
      .then(response => {
        const campaignsLista = response.data

        const listaDeCampanhas = []
        // eslint-disable-next-line no-unused-vars
        campaignsLista.map(c => listaDeCampanhas.push({ label: c.campaignName, value: c.campaignToken }))
        listaDeCampanhas.unshift({ label: 'Todas as campanhas', value: null })
        campaignsList.value = listaDeCampanhas
        return response
      })
      .catch(error => error)
  }

  const fetchDashboardConversion = () => {
    useJwt.dashboardConversion({
      initDate: periodDateFilter.value === null || periodDateFilter.value === ''
        ? `${initDate.value}T00:00:00.0000001`
        : `${invertDateOrder(
          periodDateFilter.value.slice(0, 10),
        )}T00:00:00.0000001`,

      endDate: periodDateFilter.value === null || periodDateFilter.value === ''
        ? `${endDate.value}T23:59:59.9999999`
        : `${invertDateOrder(
          periodDateFilter.value.slice(15, 25),
        )}T23:59:59.9999999`,
      campaignTokens: campaignsFilterToken.value === null ? [] : [campaignsFilterToken.value],
    })
      .then(response => {
        const {
          newLeads,
          canceled,
          inProgress,
          confirmed,
          accessPercentage,
          sharing,
          engagementPercentage,
          campaignResume,
          rewardTypes,
          conversionPercentage,
          sharingMedium,
          newLeadsData: newLeadsTrackerData,
        } = response.data.result

        dashboardConversionData.value = response.data.result

        newLeadsData.value = newLeads
        canceledData.value = canceled
        inProgressData.value = inProgress
        confirmedData.value = confirmed
        sharingData.value = sharing

        sharedMethodsData.value = sharingMedium

        conversionPercentageData.value = {
          lead: campaignResume.leads,
          conversions: campaignResume.conversions,
          series: [conversionPercentage],
        }

        campaignResumeData.value = [
          {
            ...campaignResumeData.value[0],
            title: campaignResume.views,
          },
          {
            ...campaignResumeData.value[1],
            title: campaignResume.uniqueAccess,
          },
          {
            ...campaignResumeData.value[2],
            title: campaignResume.leads,
          },
          {
            ...campaignResumeData.value[3],
            title: campaignResume.conversions,
          },
        ]
        accessPercentageData.value = accessPercentage.toFixed(0)
        engagementPercentageData.value = engagementPercentage.toFixed(0)

        rewardTypesData.value = [
          {
            ...rewardTypesData.value[0],
            title: rewardTypes.money,
          },
          {
            ...rewardTypesData.value[1],
            title: rewardTypes.voucher,
          },
          {
            ...rewardTypesData.value[2],
            title: rewardTypes.percent,
          },
        ]

        newLeadsTrackerFormattedData.value = {
          ...newLeadsTrackerData,
          percent: [newLeadsTrackerData.percent],
        }

        return response
      })
      .catch(error => {
        newLeadsData.value = error.response.data.result.newLeads
        canceledData.value = error.response.data.result.canceled
        inProgressData.value = error.response.data.result.inProgress
        confirmedData.value = error.response.data.result.confirmed
        sharingData.value = error.response.data.result.sharing

        sharedMethodsData.value = []

        conversionPercentageData.value = {
          lead: 0,
          conversions: 0,
          series: [0],
        }

        campaignResumeData.value = [
          {
            ...campaignResumeData.value[0],
            title: 0,
          },
          {
            ...campaignResumeData.value[1],
            title: 0,
          },
          {
            ...campaignResumeData.value[2],
            title: 0,
          },
          {
            ...campaignResumeData.value[3],
            title: 0,
          },
        ]
        accessPercentageData.value = error.response.data.result.accessPercentage
        engagementPercentageData.value = error.response.data.result.engagementPercentage

        rewardTypesData.value = [
          {
            ...rewardTypesData.value[0],
            title: 0,
          },
          {
            ...rewardTypesData.value[1],
            title: 0,
          },
          {
            ...rewardTypesData.value[2],
            title: 0,
          },
        ]

        newLeadsTrackerFormattedData.value = {
          newleads: 0,
          inProgress: 0,
          completed: 0,
          percent: [0],
        }

        toast({
          component: ToastificationContent,
          props: {
            title: 'Ocorreu um erro',
            text: error.response.data.errors[0],
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchTableOfCampaigns = () => {
    useJwt.tableOfCampaingConversionDashboard({
      initDate: periodDateFilter.value === null || periodDateFilter.value === ''
        ? `${initDate.value}T00:00:00.0000001`
        : `${invertDateOrder(
          periodDateFilter.value.slice(0, 10),
        )}T00:00:00.0000001`,

      endDate: periodDateFilter.value === null || periodDateFilter.value === ''
        ? `${endDate.value}T23:59:59.9999999`
        : `${invertDateOrder(
          periodDateFilter.value.slice(15, 25),
        )}T23:59:59.9999999`,
      campaignTokens: [],
    })
      .then(response => {
        tableCampaingns.value = response.data.result
        return response
      })
      .catch(() => {
        tableCampaingns.value = null
      })
  }

  return {
    dashboardConversionData,
    campaignsList,
    periodDateFilter,
    campaignsFilterToken,
    fetchCampaigns,
    tableCampaingns,
    newLeadsData,
    canceledData,
    inProgressData,
    confirmedData,
    sharingData,
    sharedMethodsData,
    campaignResumeData,
    conversionPercentageData,
    accessPercentageData,
    engagementPercentageData,
    rewardTypesData,
    newLeadsTrackerFormattedData,
    fetchDashboardConversion,
    fetchTableOfCampaigns,
  }
}
