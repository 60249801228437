<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
    style="flex: 1"
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Todos os registros
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <!-- <div class="d-flex justify-content-between"> -->
      <div :style="data && `display: grid; grid-template-columns: repeat(${data.length}, 1fr)`">
        <div
          v-for="item in data"
          :key="item.icon"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-body
              class="my-auto d-flex"
              :class="verticalItems && 'flex-column align-items-center'"
            >
              <b-avatar
                size="48"
                :variant="item.color"
                :class="verticalItems && 'd-flex align-items-center justify-content-center'"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
              <b-card-text
                class="font-small-3 mb-0 mx-1"
                :class="verticalItems && 'd-flex align-items-center justify-content-center flex-column mt-1 text-center'"
              >
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    // BRow,
    // BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,

    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    verticalItems: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
